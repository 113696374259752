
<template>
  <div class="box w446 h120 flex row-center col-center row-column">
    <div class="fs20 mb3" style="color:#08F4DA">{{ title }}</div>
    <div :class="['count-flop', type == 2 ? 'color-b' : '']" :key="compKey">
      <div :class="item != ',' ? 'count-flop-box' : 'count-flop-point'" v-for="(item, index) in value" :key="index">
        <div v-if="item != ','" class="count-flop-content" :class="['rolling_' + item]">
          <div v-for="(item2, index2) in numberList" :key="index2" class="count-flop-num fm-n">{{ item2 }}</div>
        </div>
        <div v-else class="count-flop-content">,</div>
      </div>
      <div v-if="suffix" class="fs16">{{ suffix }}</div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'CountFlop',
  data() {
    return {
      value: [],
      numberList: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
      compKey: 0
    };
  },
  props: ["val", "suffix", 'type', 'title'],
  watch: {
    val() {
      this.value = this.val.toString().split("");
      this.compKey += 1;
    }
  },
  created() {
    this.value = this.val.toString().split("");

  },
};
</script>
<style scoped >
.box {
  background: url("~@/assets/img/4.0/countNumBg.png") no-repeat;
  background-size: 100% 100%;
}

.count-flop {
  position: relative;
  display: inline-block;
  font-size: 0;
  /* 可更改 */
  height: 64px;
  line-height: 64px;
  font-size: 72px;
  color: #08f4da;
}

.color-b {
  color: rgba(8, 186, 244, 1);
}

.count-flop>div {
  position: relative;
  display: inline-block;
  overflow: hidden;
  height: 100%;
}

.count-flop-box {
  width: 48px;
  /* border: 1px solid rgba(72, 152, 241, 0.3); */
  line-height: 64px;
  /* border-radius: 6px; */
  background: rgba(8, 244, 218, 0.1);

  +.count-flop-box {
    /* 可更改 */
    margin-left: 10px;
  }
}

.count-flop-point {
  /* 可更改 */
  margin-right: 5px;
  width: 10px;
}

.count-flop-content {
  font-family: MicrosoftYaHei-Bold;
  text-align: center;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  animation-fill-mode: forwards !important;
}

.rolling_0 {
  animation: rolling_0 2.1s ease;
}

@keyframes rolling_0 {
  from {
    transform: translateY(-90%);
  }

  to {
    transform: translateY(0);
  }
}

.rolling_1 {
  animation: rolling_1 3s ease;
}

@keyframes rolling_1 {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(-10%);
  }
}

.rolling_2 {
  animation: rolling_2 2.1s ease;
}

@keyframes rolling_2 {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(-20%);
  }
}

.rolling_3 {
  animation: rolling_3 3s ease;
}

@keyframes rolling_3 {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(-30%);
  }
}

.rolling_4 {
  animation: rolling_4 2.1s ease;
}

@keyframes rolling_4 {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(-40%);
  }
}

.rolling_5 {
  animation: rolling_5 3s ease;
}

@keyframes rolling_5 {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(-50%);
  }
}

.rolling_6 {
  animation: rolling_6 2.1s ease;
}

@keyframes rolling_6 {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(-60%);
  }
}

.rolling_7 {
  animation: rolling_7 3.1s ease;
}

@keyframes rolling_7 {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(-70%);
  }
}

.rolling_8 {
  animation: rolling_8 2.1s ease;
}

@keyframes rolling_8 {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(-80%);
  }
}

.rolling_9 {
  animation: rolling_9 3.6s ease;
}

@keyframes rolling_9 {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(-90%);
  }
}</style>